@import '~styles/variables';
@import '~styles/mixins';
.tutorCarWrap{
    background: #fff;
    padding:23px;
    display: flex;
    height: 227px;
    border-radius: 10px;
    cursor: pointer;
    .priceStar{
        @include display-flex(space-between);
    }
    &:hover{
        box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
        .leftContent{
            .priceStar{
                .priceText{
                    color: #FFA703;
                }
            }
        }
        .rightContent{
            .name{
                color: #FFA703;
            }
        }
    }
 
}
.leftContent{
    width: 38%;
    max-width: 160px;
    min-width: 118px;
    border-radius: 8px;
    overflow: hidden;
    background: #fff;
    position: relative;
    @include background-config(no-repeat, cover, center);
    .priceStar{
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: rgba($color: #000000, $alpha:0.6);
      color: #fff;
      padding:0 8px;
      height: 42px;
      @include maxWidth766{
        height: auto;
        @include display-flex(flex-start,$direction: column);
      }
      .priceText{
        @include font-style(24px,700, $color:$th-white, $lineHeight:normal);
        font-family:'Poppins', sans-serif;
        @include maxWidth766{
          font-size: 20px;
        }
      }
      .priceHour{
        display: flex;
        align-items: center;
      }
      .smailText{
        @include font-style(12px, 400);
        font-family: 'Poppins',sans-serif;
        margin-left: 3px;
      }
      .startText{
        @include font-style(14px, 400);
        font-family: 'Poppins',sans-serif;
        display: flex;
        align-items: center;
        i{
            margin-right: 3px;
        }

      }
    }

}
.rightContent{
    flex: 1;
    margin-left: 20px;
    overflow: hidden;
    position: relative;
    .name{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        @include font-style(24px,700,$color: $th-black-A1, $lineHeight:normal);
        font-family:'Poppins', sans-serif;
    }
    .description{
        @include font-style(14px,400, $color: $th-black-A1, $lineHeight:normal);
        font-family:'Poppins', sans-serif;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .tagRow{
        margin-top: 12px;
    }
    .tagColum{
        display: flex;
        margin-bottom: 4px;
        @include font-style(12px, 400, $th-black-A1, $lineHeight:normal);
        font-family:'Poppins', sans-serif;
        overflow: hidden;
        .text{
            display: inline-block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .countLabelNumber{
            border-radius: 3px;
            background-color: #9658C0;
            font-size: 12px;
            color: #ffffff;
            margin-right: 2px;
        }
    }

    .operate{
        @include display-flex(space-between);
        width: 82%;
        position: absolute;
        bottom: 0;
    }
    .operateItem{
        @include display-flex($direction: column);
        @include font-style(12px,500, $color: $th-black-A1, $lineHeight:normal);
       
        font-family:'Poppins', sans-serif;
    }  
    .operateItemIcon{
        height: 20px;
        margin-bottom: 5px;
        display: inline-block;
        background: {
            size: contain;
            position: center;
            repeat: no-repeat;
          };

    }
    .messages {
        background: {
            image: url("../../static/img/message.png");
          };
      }
      .share {
        background: {
            image: url("../../static/img/share.png");
          };
      }
      .book {
        background: {
            image: url("../../static/img/book.png");
          };
      }
    
}
// mobile
@media screen and (max-width:768px){
    .rightContent{
        .name{
            font-size:16px ;
            line-height: 24px;
        }
        .tagColum{
            margin-bottom: 0;
        }
        .description{
            font-size: 12px;
            line-height: 16px;
        }
    }
}
@media screen and (max-width:651px){
    .tutorCarWrap{
        padding: 16px;
        .rightContent{
            .operate{
                width: 100%;
            }
       }
     }
}
